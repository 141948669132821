import React, { FC, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
// import { useHistory } from "react-router-dom";
// import queryString from "query-string";
// import dayjs from 'dayjs';
import isEmpty from '../../../Utilities/is_empty';
import { geocodeByPlaceId, getLatLng } from "react-places-autocomplete";
import { getTimeZoneByLocation } from '../../../Actions/companyActions';
import { AddressChangeHandlerType } from "../../../Interfaces/PayloadsAndResponses/Address";
import { addNewLocationData } from '../../../Actions/companyActions';
import { getloggedInUser } from '../../../Utilities/getloggedInUser';
import { AddressesActiveAttributes } from "../../../Interfaces/PayloadsAndResponses/Company";

type PublicRestaurantMenuProps = {
  address_id?: string;
  rest_id?: string;
};

const UserLocation: FC<PublicRestaurantMenuProps> = ({ address_id, rest_id }) => {
  const dispatch: any = useDispatch();
  const loggedInCompanyId = getloggedInUser().loggedInUserCompany?.id;
  // const searchParams = queryString.parse(window.location.search);
  // const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  // const [locationData, setLocationData] = useState<any>(null);
  const [userPlaceId] = useState<any>(address_id)

  useEffect(() => {
    userPlaceId && fetchAddress(userPlaceId)
    // window.location.replace('https://chowmill.com/')

    // eslint-disable-next-line
  }, [userPlaceId]);

  const fetchAddress = (address: string) => {
    let city: string, state: string, street_number: string, zip: number, street: string;

    geocodeByPlaceId(address)
      .then(async results => {
        results && results[0] && results[0].address_components.map((item) => {
          item.types?.forEach(type => {
            if (type === 'locality') {
              city = item.long_name;
            }
            if (type === 'administrative_area_level_1') {
              state = item.short_name;
            }
            if (type === 'street_number') {
              street_number = item.short_name;
            }
            if (type === 'route') {
              street = item.short_name;
            }
            if (type === 'postal_code') {
              zip = +item.short_name;
            }
          })
          return true;
        });

        let latLang = await getLatLng(results[0]);
        let response = await getTimeZoneByLocation(latLang.lat, latLang.lng);
        let time_zone = response?.timeZoneId;
        const PlaceIdData = {
          placeId: address
        }
        localStorage.setItem("address-placeId", JSON.stringify(PlaceIdData));
        let formatted_address = `${street_number ? `${street_number} ` : ''}${street ? `${street}` : ''}${(street || street_number) && city ? ',' : ''}${city ? ` ${city}` : ''}`;
        saveAddress({
          city,
          state,
          zip,
          street_number,
          street,
          address_line: results[0]?.formatted_address,
          longitude: latLang.lng,
          latitude: latLang.lat,
          time_zone,
          formatted_address
        });
        return time_zone;
      })
      .then(time_zone => {
        // For URLs Redirect

        // let zonedTimeFtn = await getZonedTime(new Date(), 'EEE. MMM dd h:mma, zzzz', timezone?? '')
        // let zonedDateTime = new Date(new Date().toLocaleString("en-US", { timeZone: time_zone }))
        // let thresholdDateTime = new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 14:00:00') // date for marketplace with threshold: 2pm
        // const dateForMarketplace = dayjs().add((dayjs(zonedDateTime).isAfter(new Date(thresholdDateTime))? 2 : 1), 'day').format('M-D-YYYY');
        // const hasAddress = JSON.parse(localStorage.getItem('address') || '{}');

        // if (user?.phone_number) {
        //   history.push(`/menu/${dateForMarketplace}`)
        // } else if (hasAddress.latitude) {
        //   history.push(`/home/${dateForMarketplace}`)
        // }
      })
      .catch(error => console.error('Error', error));
  };

  const saveAddress: AddressChangeHandlerType = (address) => {
    if (user?.phone_number) {
      const formattedAddressData: AddressesActiveAttributes = {
        address_line: address.address_line,
        address_name: address.address_name,
        suite_no: address.suite_no ?? '',
        longitude: address.longitude ?? 0,
        latitude: address.latitude ?? 0,
        time_zone: address.time_zone ?? '',
        delivery_instructions: address.delivery_instructions ?? '',
        street_number: address.street_number ?? '',
        street: address.street ?? '',
        city: address.city ?? '',
        state: address.state ?? '',
        zip: address.zip ?? 0,
        created_from_frontend: true
      }
      const formattedLocationData = {
        company: {
          addresses_active_attributes: [formattedAddressData]
        }
      };

      dispatch(addNewLocationData(+loggedInCompanyId, formattedLocationData));
    } else {
      if (!isEmpty(address?.latitude)) {
        localStorage.setItem("address", JSON.stringify(address));
      }
    }
  };

  return (
    <div>
    </div>
  );
}

export default UserLocation

