import { useState, useLayoutEffect } from 'react';
import { ScreenWidths } from '../getResponsiveNoOfSildes';


type ScreenCategory =
  | 'Extra Extra Large Desktops'
  | 'Extra Large Desktops'
  | 'Large Desktops'
  | 'Small Desktops'
  | 'Large Laptops'
  | 'Small Laptops'
  | 'Tablets'
  | 'Large Mobiles'
  | 'Medium Mobiles'
  | 'Small Mobiles';

export interface ScreenStyles {
  width: string;
  padding: string;
}

const getRawWidth = (screenCategory: ScreenCategory): string => {
  switch (screenCategory) {
    case 'Extra Extra Large Desktops':
      return '335';
    case 'Extra Large Desktops':
      return '323';
    case 'Large Desktops':
      return '310';
    case 'Small Desktops':
      return '297';
    case 'Large Laptops':
      return '282';
    case 'Small Laptops':
      return '265';
    case 'Tablets':
      return '248';
    case 'Large Mobiles':
      return '231';
    case 'Medium Mobiles':
      return '213';
    case 'Small Mobiles':
      return '194';
    default:
      return '194'; // Default value for unknown category
  }
};

const getScreenCategory = (width: number): ScreenCategory => {
  if (width >= ScreenWidths.ExtraExtraLargeDesktops) return 'Extra Extra Large Desktops';
  if (width >= ScreenWidths.ExtraLargeDesktops) return 'Extra Large Desktops';
  if (width >= ScreenWidths.LargeDesktops) return 'Large Desktops';
  if (width >= ScreenWidths.SmallDesktops) return 'Small Desktops';
  if (width >= ScreenWidths.LargeLaptops) return 'Large Laptops';
  if (width >= ScreenWidths.SmallLaptops) return 'Small Laptops';
  if (width >= ScreenWidths.Tablets) return 'Tablets';
  if (width >= ScreenWidths.LargeMobiles) return 'Large Mobiles';
  if (width >= ScreenWidths.MediumMobiles) return 'Medium Mobiles';
  return 'Small Mobiles'; // Default for very small devices
};

const getFormStyle = (screenCategory: ScreenCategory): ScreenStyles => {
  switch (screenCategory) {
    case 'Extra Extra Large Desktops':
      return { width: '380px', padding: '0 30px 0 15px' };
    case 'Extra Large Desktops':
      return { width: '360px', padding: '0 25px 0 12px' };
    case 'Large Desktops':
      return { width: '340px', padding: '0 20px 0 10px' };
    case 'Small Desktops':
      return { width: '320px', padding: '0 15px 0 8px' };
    case 'Large Laptops':
      return { width: '300px', padding: '0 12px 0 6px' };
    case 'Small Laptops':
      return { width: '280px', padding: '0 10px 0 5px' };
    case 'Tablets':
      return { width: '260px', padding: '0 8px 0 4px' };
    case 'Large Mobiles':
      return { width: '240px', padding: '0 6px 0 3px' };
    case 'Medium Mobiles':
      return { width: '220px', padding: '0 5px 0 2px' };
    case 'Small Mobiles':
      return { width: '200px', padding: '0 4px 0 2px' };
    default:
      return { width: '360px', padding: '0 20px 0 10px' }; // Default style
  }
};

const useScreenSize = (): { width: number; rawWidth: string; styles: ScreenStyles } => {
  const [screenCategory, setScreenCategory] = useState<ScreenCategory>(getScreenCategory(window.innerWidth));

  useLayoutEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const category = getScreenCategory(width);
      setScreenCategory(category);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const styles = getFormStyle(screenCategory);

  const rawWidth = getRawWidth(screenCategory);

  return {
    width: window.innerWidth, 
    rawWidth,               
    styles,                   
  };
};

export default useScreenSize;
