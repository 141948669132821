import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
// import { WithTranslation } from 'react-i18next';
import Header from '../../../Common/HeaderFooter/Header';
import { sendInvite } from '../../../Actions/inviteActions';
import isEmpty from '../../../Utilities/is_empty';
import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import { inviteUserTranslations } from '../../../Translations/inviteUserTranslations';
import { emailPattern } from '../../../Utilities/validationPatterns';
import { useCSVReader } from 'react-papaparse';
import '../codemirror.css';

export type InviteUsersPayload = {
  emails: string;
};

// type InviteUsersProps = {
//     i18n: WithTranslation | null;
//     sendInvite: (inviteData: InviteUsersPayload) => void;
// }

type InviteUserState = {
  emails: string;
  bulkEmailString: string;
  firstName: string;
  lastName: string;
  nameError: boolean;
  userType: any;
  initialFormState: boolean;
  validForm: boolean;
  csvFile: FileInfoState;
  csvData: any;
  isChecked: boolean;
  isCheckedToggle: boolean;
};

type FileInfoState = {
  name: string;
  size: number;
};

const userTypeOptions = [
  { value: 'company_admin', label: 'Company Admin' },
  { value: 'company_user', label: 'Regular User' },
  { value: 'company_manager', label: 'Company Manager' },
  { value: 'unsubsidized_user', label: 'Unsubsidized User' }
]

const InviteUsers: FC = () => {
  const { CSVReader } = useCSVReader();
  const history = useHistory();
  const dispatch: any = useDispatch();

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const { i18n } = useSelector((state: ReduxRootState) => state.i18n);

  const initializeInviteUser = {
    emails: '',
    bulkEmailString: '',
    firstName: '',
    lastName: '',
    nameError: true,
    userType: {
      value: 'company_user',
      label: 'Regular User',
    },
    initialFormState: false,
    validForm: false,
    csvFile: {
      name: '',
      size: 0,
    },
    csvData: '',
    isChecked: false,
    isCheckedToggle: false,
  };

  // const user_type = useSelector((state: ReduxRootState) => state.users.user_types);
  const [inviteUser, setInvitUser] = useState<InviteUserState>(initializeInviteUser);
  const [fileInfo, setFileInfo] = useState<FileInfoState>({ name: '', size: 0 });
  const [validData, setValidData] = useState<boolean>(false);

  const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    Validateform(inviteUser?.firstName, inviteUser.lastName, inviteUser.emails);
    if (inviteUser.bulkEmailString) {
      Validateform(inviteUser.firstName, inviteUser.lastName, inviteUser.bulkEmailString);
    }
    setInvitUser({ ...inviteUser, isChecked: e.target.checked });
    // this.setState({ isChecked: e.target.checked })
  };
  const onToggled = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvitUser({ ...inviteUser, isCheckedToggle: !inviteUser.isCheckedToggle });
    // this.setState({isCheckedToggle: !this.state.isCheckedToggle})
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    //const name = event.target.name;
    //const value = event.target.value;
    // if(name === 'firstName'){
    //  this.setState({firstName:value})
    // }
    // if(name === 'lastName'){
    //   this.setState({initialFormState:true})
    //   this.setState({lastName:value})
    // }
    if (event.target.name === 'emails') {
      //   this.setState({initialFormState:true})
      setInvitUser({ ...inviteUser, initialFormState: true });
      if (ValidateEmail(event.target.value)) {
        setInvitUser({ ...inviteUser, [event.target.name]: event.target.value });
        // this.setState({ emails: value });
      }
    } else {
      setInvitUser({ ...inviteUser, initialFormState: true });
      setInvitUser({ ...inviteUser, [event.target.name]: event.target.value });
    }

    // EmailStringCreator()
    Validateform(inviteUser.firstName, inviteUser.lastName, inviteUser.emails)
  };

  const EmailStringCreator = () => {
    let emailString = inviteUser.emails + '\t' + inviteUser.firstName + '\t' + inviteUser.lastName + '\t' + inviteUser.userType?.value;
    return emailString;
  };

  const Validateform = (f_name: string, l_name: string, email: string) => {
    if (!isEmpty(f_name) && !isEmpty(l_name) && !isEmpty(email)) {
      //   this.setState({ validForm: true })
      // setInvitUser({ ...inviteUser, validForm: true });
      setValidData(true);
    } else if (!isEmpty(email)) {
      //   this.setState({ validForm: true })
      // setInvitUser({ ...inviteUser, validForm: true });
      setValidData(true);
    } else {
      // this.setState({ validForm: false })
      // setInvitUser({ ...inviteUser, validForm: false });
      setValidData(false);
    }
  };

  const ValidateEmail = (mail: any) => {
    // if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {  //old email test patttern
    if (emailPattern.test(mail)) {
      return true;
    }
    return false;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // let emailString = EmailStringCreator();
    let emailString = '';

    if (fileInfo.name) {
      // inviteUser.bulkEmailString
      if (inviteUser.emails) {
        // this.setState({ emails: this.state.emails+'\n'+ this.state.bulkEmailString })
        emailString = inviteUser.emails + '\n' + inviteUser.bulkEmailString;
      } else {
        emailString = inviteUser.bulkEmailString;
        // this.setState({ emails: str })
      }
    } else {
      emailString = EmailStringCreator();
    }
    const inviteData = { emails: emailString };

    dispatch(sendInvite(inviteData));
    history.push('/confirm-invite');
  };

  const selectUserType = (userType: any) => {
    setInvitUser({ ...inviteUser, userType: userType });
    // this.setState({userType})
    // EmailStringCreator();
  };

  const handleForce = (data?: any) => {
    // this.setState({ csvData: data })
    // this.setState({csvFile:{
    //   filename:fileInfo.name,
    //   size:fileInfo.size
    // }})

    setInvitUser({ ...inviteUser, csvData: data });
    if (data) {
      let str = '';
      let f_name = '';
      let l_name = '';
      let user_type = '';
      data.forEach((val: any, idx: any) => {
        // str = str + val.email+'\t'+''+'\t'+''+'\t'+''+'\n'
        if (idx === 0) {
          str = '';
        } else {

          str = val[0] !== '' ? str + val[0] + '\t' + (f_name[1] ?? '') + '\t' + (l_name[2] ?? '') + '\t' + (user_type[3] ?? '') + '\n' : str;
          // str = str + val.email+'\t'+(f_name ?? '')+'\t'+(l_name ?? '')+'\t'+(user_type ?? '')+'\n'
        }
      });
      setInvitUser({ ...inviteUser, bulkEmailString: str });
      // this.setState({bulkEmailString:str})
      Validateform(f_name, l_name, inviteUser.bulkEmailString);
    }
  };

  useEffect(() => {
    inviteUserTranslations();
  }, []);

  return (
    <React.Fragment>
      <Header />
      {!user?.allow_admin_to_manage_users ? (
        <div className='container m-auto pt-5 text-left top-spaces-5-5rem'>
          <div className='row p-3 mt-3'>
            <h2 className='text-danger m-auto'>
              {i18n && i18n?.t('You are not allowed to manage users.')}
            </h2>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className='invite-user-page-background h-100'>
            <div className='invite-user-page-background'>
              <div>
                <div className='container m-auto pt-5 top-spaces-5-5rem'>
                  <div
                    className='row d-flex align-items-center my-3 px-3 px-md-0'
                    style={{ gap: 10 }}
                  >
                    <Link style={{ color: '#157FFC' }} to={`/users`}>
                      {i18n && i18n?.t('Users')}
                    </Link>
                    <i
                      style={{ fontSize: 13, color: '#157FFC', paddingTop: '3px' }}
                      className='fas fa-angle-right'
                    ></i>
                    <span style={{ color: '#6e6e73' }}>{i18n && i18n?.t('Invite Users')}</span>
                  </div>
                  <div className='row p-3 px-md-0 invite-users-page-header'>
                    <div className='p-3'>
                      <h2 style={{ color: 'black', fontSize: '26px' }}>
                        {' '}
                        {i18n && i18n?.t('Invite Users')}
                      </h2>
                      <p className='lead' style={{ color: '#6e6e73', fontSize: '18px' }}>
                        {i18n && i18n?.t('Invited users ‘ll receive an email invitation asking them to setup their accounts and begin ordering food.')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='container'>
                <form onSubmit={onSubmit}>
                  <div className='row bg-white form-border-radius shadow-sm mt-4 p-3 '>
                    <div
                      className='my-3'
                      style={{ fontSize: '18px', opacity: '0.5' }}
                    ></div>
                    <div className='form-heading-text pl-2'>{i18n && i18n?.t('Kindly fill the form to invite the user')}</div>
                    <div className='col-12 form-test mb-3 px-0'>
                      <div className='form-row col-lg-12 px-2 pt-3'>
                        <div className='form-input col-6'>
                          <label className='my-1'>
                            {i18n && i18n?.t('First Name')} <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            type='text'
                            name='firstName'
                            className='form-control border rounded'
                            placeholder='John'
                            onChange={changeHandler}
                          />
                        </div>
                        <div className='form-input col-6 px-2'>
                          <label className='my-1'>
                            {i18n && i18n?.t('Last Name')} <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            type='text'
                            name='lastName'
                            className='form-control border rounded'
                            placeholder='Doe'
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                      <div className='form-row col-lg-12 px-2'>
                        <div className='form-input col-6'>
                          <label className='my-1'>
                            {i18n && i18n?.t('Email Address')} <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            type='text'
                            name='emails'
                            className='form-control border rounded'
                            placeholder='user@example.com'
                            onChange={changeHandler}
                          />
                          {inviteUser?.initialFormState &&
                            inviteUser?.emails === '' ? (
                            <small className='' style={{ color: 'red' }}>
                              {i18n && i18n?.t('Please enter a valid email')}
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className='form-input col-6 px-2'>
                          <label className='my-1'>{i18n && i18n?.t('User Type')}</label>
                          <Select
                            options={userTypeOptions}
                            defaultValue={{
                              value: 'company_user',
                              label: 'Regular User',
                            }}
                            onChange={selectUserType}
                          />
                        </div>
                      </div>

                      {/* <div className='form-input col-3 px-0 '>
                      <label htmlFor='' className='my-1'>User Type</label>
                      <input type='text' name='user_type' id='' className='input-itself border rounded' placeholder='select user type' onChange={(e) => this.changeCodemiror(e)} />
                    </div> */}
                    </div>
                  </div>
                  <div className='row d-flex align-items-center mt-2 px-3 px-md-0'>
                    <div
                      style={{
                        fontWeight: '600',
                        fontSize: '18px',
                        color: '#157FFC',
                      }}
                    >
                      {i18n && i18n?.t('Bulk Users Upload')}
                    </div>
                    <div className='custom-control custom-switch  mr-0 mx-2'>
                      <input
                        type='checkbox'
                        className='custom-control-input'
                        id='mealTypeCheck'
                        name='mealTypeCheck'
                        checked={inviteUser?.isCheckedToggle}
                        onChange={onToggled}
                      />
                      <label
                        className='custom-control-label float-left'
                        htmlFor='mealTypeCheck'
                        style={{ backgroundColor: 'black', color: 'white' }}
                      >
                        {' '}
                      </label>
                    </div>
                  </div>
                  <div
                    className={`row ${inviteUser?.isCheckedToggle
                        ? 'bulkToggleTrue'
                        : 'bulkToggleFalse'
                      } mt-2 rounded`}
                  >
                    {inviteUser?.isCheckedToggle && (
                      <div className='bg-white d-flex flex-column justify-content-center align-items-center uploadDiv'>
                        <div className='d-flex flex-column justify-content-center'>
                          <div className='d-flex justify-content-center align-items-center'>
                            <span
                              className='rounded-circle p-2 m-1'
                              style={{ color: 'white', backgroundColor: '#157FFC' }}
                            >
                              <i className='fas fa-cloud-upload-alt fa-2x'></i>
                            </span>
                          </div>
                          {fileInfo.name ? (
                            <span
                              className='badge badge-pill p-2 m-1'
                              style={{ backgroundColor: '#EFF0F2', opacity: '0.5' }}
                            >
                              {fileInfo.name}
                              <span className='px-2'>{fileInfo.size}Kb</span>
                              <i
                                className='ml-2 far fa-times-circle cursor-pointer'
                                onClick={() => setFileInfo({ name: "", size: 0 })}
                              ></i>
                            </span>
                          ) : (
                            <>
                              {/* <label htmlFor='uploadFile'>Drag and Drop Files or Upload, or <span style={{ 'color': '#157FFC', textDecoration: 'none' }}>Browse</span></label> */}
                              {/* <CSVReader
                                onFileLoaded={handleForce}
                                parserOptions={papaparseOptions}
                                inputId='uploadFile'
                                inputStyle={{ display: 'none' }}
                              /> */}
                              <CSVReader onUploadAccepted={(results: any) => { handleForce(results.data) }} >
                                {({ getRootProps, acceptedFile }: any) => (
                                  <>
                                    {!acceptedFile && (
                                      <label
                                        htmlFor='uploadFile'
                                        {...getRootProps()}
                                      >
                                        {i18n && i18n?.t('Drag and drop files, or')}
                                        <span
                                          style={{
                                            color: '#157FFC',
                                            textDecoration: 'none',
                                          }}
                                        >
                                          Browse
                                        </span>
                                      </label>
                                    )}
                                    {acceptedFile &&
                                      setFileInfo({
                                        name: acceptedFile.name,
                                        size: acceptedFile.size,
                                      })}
                                  </>
                                )}
                              </CSVReader>
                            </>
                          )}
                        </div>
                        {!fileInfo.name && (
                          <p style={{ opacity: '0.5' }}>
                            {i18n && i18n?.t('Supports only email CSV files.')}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  <div className='row mt-2 d-flex flex-column px-3 px-md-0'>
                    <div className='d-flex align-items-start'>
                      <input className='check-box-margin'
                        type='checkbox'
                        name='agree'
                        id='agree'
                        defaultValue={1}
                        checked={inviteUser?.isChecked}
                        onChange={onChangeCheckbox}
                      />
                      <label htmlFor='agree' className='mb-0'>
                        {' '}
                        &nbsp;
                        {i18n && i18n?.t('I agree to invite this list of users to the Chowmill platform allowing these users to place orders.')}
                      </label>
                    </div>
                    <div>
                      {validData && inviteUser?.isChecked ? (
                        <button
                          type="submit"
                          className='mt-3 btn btn-lg btn-primary-cmill float-right invite-user-confirm-btn'
                          style={{ marginLeft: 'auto', fontWeight: '600' }}
                        >
                          {i18n && i18n?.t('Proceed')}
                        </button>
                      ) : (
                        <button
                          className='mt-3 btn btn-lg btn-primary-cmill float-right invite-user-confirm-btn'
                          style={{ marginLeft: 'auto', fontWeight: '600' }}
                          disabled
                        >
                          {i18n && i18n?.t('Proceed')}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default InviteUsers;
