import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { AddressChangeHandler } from '../../Pages/AccountDetail/Forms/AddLocationForm';
import ButtonSpinner from '../Spinners/ButtonSpinner';
import isEmpty from '../../Utilities/is_empty';
import { hasValueInKey } from '../../Utilities/functions';
import { addNewLocationData, getAddressStringByLatLong } from '../../Actions/companyActions';
import { getloggedInUser } from '../../Utilities/getloggedInUser';
import { Addresses, ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { MarketPlaceCompanyAddress } from '../../Interfaces/PayloadsAndResponses/Company';
import { NewAddressData } from '../../Pages/AccountDetail/Modals/AddNewLocationModal';
import { initialAddressData } from '../../Pages/AccountDetail/Modals/AddNewDepartmentModal';
import { AddressDataType } from '../../Interfaces/PayloadsAndResponses/Address'
import MarketPlaceAddLocationForm from '../../Pages/AccountDetail/Forms/MarketPlaceAddLocationForm';
import { MARKETPLACE_PAGE } from '../../Actions/types';
import { showToastNotification } from '../../Utilities/showToastNotification';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '700px',
    padding: '0px',
    borderRadius: '10px',
  }
};

type InitialDataState = {
  addressName: string,
  suitNumber: string,
  deliveryInstructions: string
}


export type DeliveryAddressPopupErrors = {
  addressLine: string,
  addressName: string,
  timezone?: string
}

const initialData = {
  addressName: '',
  suitNumber: '',
  deliveryInstructions: ''
};

const initialErrors = {
  addressLine: '',
  addressName: '',
  timezone: ''
};
Modal.setAppElement('#root');
type MarketPlaceDeliveryAddressPublicPopUpProps = {
  isOpen: boolean,
  closeModal: () => void,
  addresses?: Addresses,
  setAddressInMarketPlace?: (addressId: number | AddressDataType) => void,
  selectedCompanyAddress?: MarketPlaceCompanyAddress | undefined,
  openFilter?: () => void,
  fromPublicMarketPlace: boolean,
  popUpCloseCheck?: boolean,
  changeAddressPopUpCloseCheck?: () => void,
};
const MarketPlaceDeliveryAddressPublicPopUp = forwardRef(({
  isOpen,
  closeModal,
  setAddressInMarketPlace,
  fromPublicMarketPlace,
  popUpCloseCheck,
  changeAddressPopUpCloseCheck
}: MarketPlaceDeliveryAddressPublicPopUpProps, ref) => {

  const dispatch: any = useDispatch();
  const i18n = useSelector((state: ReduxRootState) => state.i18n?.i18n);

  const { company_id } = useParams<{ company_id: string | undefined }>();
  const loggedInCompanyId = getloggedInUser().loggedInUserCompany?.id;
  const companyId = company_id || loggedInCompanyId;
  const { errors } = useSelector((state: ReduxRootState) => state.errors);
  const [_errors, setErrors] = useState<DeliveryAddressPopupErrors>(initialErrors);
  const [firstShown, setFirstShown] = useState<boolean>(false);
  const [formData, setFormData] = useState<InitialDataState>(initialData);
  const { addressName, suitNumber, deliveryInstructions } = formData;
  const [addressData, setAddressData] = useState<NewAddressData>(initialAddressData);
  const { addressLine, longitude, latitude, city, state, zipCode, streetNumber, street, timezone, formatted_address } = addressData;
  const { buttonLoading } = useSelector((state: ReduxRootState) => state.loading);
  const [showTimeZone, setShowTimeZone] = useState<boolean>(false);

  useEffect(() => {
    const getGoogleAddress = JSON.parse(localStorage.getItem('no-response') || '{}')
    if (!isEmpty(getGoogleAddress)) {
      showToastNotification('Please provide a valid address', 'Oops!', true);
    }
  }, [])

  useEffect(() => {
    let _errors = initialErrors;
    if (errors && !firstShown) {
      if (errors['addresses_active.address_line'] && errors['addresses_active.address_line'][0]) {
        _errors.addressLine = errors['addresses_active.address_line'][0];
        setErrors(_errors);
      }
      setFirstShown(true);
    }
  }, [errors, firstShown]);
  useEffect(() => {
  }, [_errors])
  useImperativeHandle(
    ref,
    () => ({
      clearErrors: () => {
        setFormData(initialData);
        setAddressData(initialAddressData);
      },
    }), []);
  useEffect(() => {
    dispatch({
      type: MARKETPLACE_PAGE,
      payload: false
    })
    popUpCloseCheck && validate()
    changeAddressPopUpCloseCheck && changeAddressPopUpCloseCheck()
    setFirstShown(true);
    // eslint-disable-next-line
  }, [popUpCloseCheck])


  const handleChange = ({ target: { name, value } }: React.BaseSyntheticEvent<Event, EventTarget & HTMLInputElement>) => {
    validate()
    name === 'addressName' && isEmpty(value) ? setErrors({ ...errors, addressName: 'Please enter address name.' }) : setErrors({ ...errors, addressName: '' })
    setFormData({ ...formData, [name]: value })
  };
  const handleAddress: AddressChangeHandler = (address) => {
    if (firstShown) {
      let errors = initialErrors;
      if (isEmpty(address)) {
        errors.addressLine = 'Address is mandatory to view available restaurants';
        setErrors(errors);
      } else {
        errors.addressLine = '';
        if (address.timezone) {
          handleTimezone(address.timezone)
          errors.timezone = ''
        } else if (address?.timezone === '') {
          setShowTimeZone(true);
        } else {
          errors.timezone = 'Please enter timezone'
        }
      }
      setErrors(errors);
    }
    !isEmpty(address) ? setErrors({ ...errors, addressLine: '' }) : setErrors({ ...errors, addressLine: 'Address is mandatory to view available restaurants' })
    const { streetNumber, street, city } = address
    let formatted_address = `${streetNumber ? `${streetNumber} ` : ''}${street ? `${street}` : ''}${(street || streetNumber) && city ? ',' : ''}${city ? ` ${city}` : ''}`;
    setAddressData({ ...addressData, ...address, formatted_address });
  };
  const handleTimezone = (timezone: string) => {
    let errors = initialErrors;
    if (isEmpty(timezone)) {
      if (firstShown) {
        validate()
        errors.timezone = 'Please enter timezone';
      }
    } else {
      errors.timezone = '';
    }
    setErrors(errors);
    setAddressData({ ...addressData, timezone });
  }
  const validate = () => {
    let errors = initialErrors;
    if (isEmpty(addressLine)) {
      errors.addressLine = 'Address is mandatory to view available restaurants';
    } else {
      errors.addressLine = '';
    }
    if (isEmpty(timezone)) {
      errors.timezone = 'Please enter timezone ';
    } else {
      errors.timezone = '';
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch({
      type: MARKETPLACE_PAGE,
      payload: true
    })
    validate()
    setFirstShown(true)
    const newLocationData = {
      address_line: addressLine,
      address_name: addressName,
      suite_no: suitNumber,
      longitude: longitude,
      latitude: latitude,
      delivery_instructions: deliveryInstructions,
      street: street,
      street_number: streetNumber,
      city: city,
      state: state,
      zip: zipCode,
      time_zone: timezone,
      formatted_address,
      created_from_frontend: true
    };
    let arrayData = [];
    arrayData.push(newLocationData);
    const formattedLocationData = {
      company: {
        addresses_active_attributes: arrayData
      }
    };
    let hasError = validate();
    if (!hasValueInKey(hasError)) {
      setFormData({} as InitialDataState);
      setAddressData({} as NewAddressData);


      if (fromPublicMarketPlace) {
        // setAddressInMarketPlace(newLocationData)
        closeModal();
        localStorage.setItem("address", JSON.stringify(newLocationData))
        const locationPopUpRedirect = () => {
          window.location.replace('/')
        }
        newLocationData?.latitude && newLocationData?.longitude && getAddressStringByLatLong(newLocationData?.latitude, newLocationData?.longitude, locationPopUpRedirect)
      } else {
        dispatch(addNewLocationData(+companyId, formattedLocationData, closeModal));
      }
      setFirstShown(false);
    }
  };

  return (
      <Modal
        isOpen={isOpen}
        style={customStyles}
        // onRequestClose={handleSubmit} // handled popup closing when we click outside the popup
        contentLabel='Delivery Address'
      >
        <div className='modal-header new-modal-header'>
        <h5 className='modal-title' id='modal-title'>
          Add New Delivery Address to Begin
        </h5>
        {/* <button
          type='button'
          // onClick={closeModal}
          className='close'
          data-dismiss='modal'
          aria-label='Close'
        >
          <span aria-hidden='true'>×</span>
        </button> */}
      </div>
      <div id='mydiv' className={`modal-body`}>

        <div id='main' className='container px-0 main-del-add-pop-up'>
          <div className='text-left market-popup'>
            <div>
              <MarketPlaceAddLocationForm
                showHeading={false}
                handleChange={handleChange}
                handleTimezone={handleTimezone}
                handleAddress={handleAddress}
                timezone={timezone}
                errors={_errors}
                focusDeliveryAddress={true}
                showTimeZone={showTimeZone}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-footer new-location-modal-footer py-2 justify-content-between mx-3 mb-2 bg-white'>
        <div className='d-flex align-items-center justify-content-center'>
          <p className='fs-18 fw-bolder text-center text-gray-200 mb-0 '> {i18n && i18n?.t('Already have an account?')} <Link to='/signin'> Login</Link></p>
        </div>
        <div className='pr-2 '>
          <button
            type='submit'
            onClick={handleSubmit}
            disabled={buttonLoading}
            className='btn restaurant-location-btn restaurant-location-btn-done'>
            {buttonLoading ? <ButtonSpinner /> : 'Get Started'}
          </button>
        </div>
      </div>
      </Modal>
  )
})

export default MarketPlaceDeliveryAddressPublicPopUp 